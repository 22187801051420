<template>
  <v-card v-if="entity.name"
          dark
          flat
          color="blue-grey darken-2"
          class="mb-4 pa-4"
  >
    <template v-if="entity.symptomId">
      <div class="mb-2 w-100p font-weight-bold">{{ entity.name }}:</div>
      <v-row>
        <v-col cols="4" class="d-flex align-center overflow-x-auto">
          <v-btn-toggle
              v-model="entity.severity"
              dense
              light
              active-class="blue-grey darken-2"
              color="white"
              borderless
              class="blue-grey darken-2"
          >
            <v-btn v-for="u in severities"
                   :key="u.text"
                   :value="u.value"
                   class="pa-0"
                   style="min-width: 30px"
            >
              {{ u.text }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col>
          <v-text-field v-model="entity.text"
                        dense
                        label="备注信息"
                        outlined
                        hide-details
          />
        </v-col>
      </v-row>
      <v-divider dark class="my-2"/>
      <v-row>
<!--        <v-col cols="2" class="font-weight-light">病症变化:</v-col>-->

        <v-col cols="4" class="d-flex align-center">
          <v-text-field v-model="entity.changeTime.num"
                        dense
                        type="number"
                        style="width:2em"
                        class="mr-2 flex-grow-0"
                        hide-details
          />
          <v-btn-toggle
              v-model="entity.changeTime.unit"
              dense
              light
              active-class="blue-grey darken-2"
              color="white"
              borderless
              class="blue-grey darken-2"
          >
            <v-btn v-for="u in units"
                   :key="u.text"
                   :value="u.value"
                   class="pa-0"
                   style="min-width: 30px"
            >
              {{ u.text }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col class="d-flex align-center">
          <v-btn-toggle
              v-model="entity.change"
              dense
              light
              active-class="blue-grey darken-2"
              color="white"
              borderless
              class="mr-2 blue-grey darken-2"
          >
            <v-btn v-for="u in changes"
                   :key="u.text"
                   :value="u.value"
                   class="pa-0 caption"
                   style="min-width: 30px"
            >
              {{ u.text }}
            </v-btn>
          </v-btn-toggle>
          <v-text-field v-model="entity.changeCause"
                        dense
                        label="变化因素"
                        outlined
                        hide-details
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="entity.diseaseId">
      <div class="mb-2 w-100p font-weight-bold">{{ entity.name }}:</div>
      <v-row>
        <v-col cols="4" class="d-flex align-center overflow-x-auto">
          <v-btn-toggle
              v-model="entity.change"
              dense
              light
              active-class="blue-grey darken-2"
              color="white"
              borderless
              class="blue-grey darken-2"
          >
            <v-btn v-for="u in changes"
                   :key="u.text"
                   :value="u.value"
                   class="pa-0"
                   style="min-width: 36px"
            >
              {{ u.text }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col>
          <v-text-field v-model="entity.text"
                        dense
                        label="备注信息"
                        outlined
                        hide-details
          />
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "EntitySeverityModifier",
  props: {
    inputEntity: Object
  },
  data: () => ({
    /**
     * {string} [symptomId]
     * {string} [diseaseId]
     * {string} name
     * {number} severity
     * {string} change
     * {string} changeTime
     * {string} changeTime.num
     * {string} changeTime.unit
     * {string} changeCause
     */
    entity: {},
    changes: [
      {text: '痊愈', value: 'heal'},
      {text: '缓解', value: 'relieve'},
      {text: '维持', value: 'maintain'},
      {text: '加重', value: 'increase'}
    ],
    units: [
      {text: '天', value: 'day'},
      {text: '周', value: 'week'},
      {text: '月', value: 'month'},
    ]
  }),
  computed: {
    ...mapState([
      "severities"
    ])
  },
  watch: {
    inputEntity: {
      immediate: true,
      handler(v) {
        if (!v || JSON.parse(JSON.stringify(v))===JSON.parse(JSON.stringify(this.entity))) return
        this.entity = JSON.parse(JSON.stringify(v))
      }
    },
    entity: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!v) return
        // if (v.change === 'heal') this.$set(v, 'severity', 0)
        // if (v.severity === 0) this.$set(v, 'change', 'heal')
        // if (v.severity > this.inputEntity.severity) this.$set(v, 'change', 'increase')
        // if (v.severity < this.inputEntity.severity) this.$set(v, 'change', 'relieve')
        this.$emit('update', v)
      }
    }
  }
}
</script>

<style scoped>

</style>